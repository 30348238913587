import { Link, graphql } from 'gatsby';
import React from 'react';

import sign from '../assets/images/sign-new.jpg';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Service from '../components/service';
import StyledButton from '../components/styledButton';
import './cities.css';

const Cities = ({ data }) => {
  const cities = data.cities.edges;
  const services = data.services.edges;

  return (
    <Layout
      headerClass='relative bg-white'
      hasHero={false}
      heroSize='20rem'
      heroImage={sign}
    >
      <SEO
        title='Cities We Serve | Burial, Funeral'
        description='City of Oaks Cremation is a full-service funeral home in Raleigh serving all of NC and beyond, including the following cities.'
      />

      <div className='flex flex-col mt-10 md:mt-16'>
        <h1 className='text-lg my-2'>
          City of Oaks Funeral Home and Cremation is located in Wake County, but
          we provide service to <Link to='/city/nc'>all of North Carolina</Link>{' '}
          and beyond, including the cities below and many others.
        </h1>
        <h2 className='text-lg my-2'>
          We're proud to cover the entire state of North Carolina and to offer
          an unmatched level of service from Asheville to Wilmington and beyond.
          We provide all of our services to all corners of N.C.—every city,
          town, hamlet, or dirt road. And we even help plenty of families out of
          state too. No matter where you're located, we're here to help.
        </h2>
        <h2 className='text-lg my-2'>
          We never charge a mileage fee within North Carolina, something that
          sets us apart from almost any other funeral home, and we list all of
          our prices so that you'll always know exactly what to expect.
        </h2>
        <h2 className='text-lg mt-2 mb-6'>
          This is just a small sample of the many cities we serve each and every
          week. Whether your city is listed or not, give us a call us and we'll
          come to you.
        </h2>
        <div className='list-column-container'>
          <ul>
            {cities.map(({ node }) => (
              <li className='hover:text-green-700'>
                <Link to={`${node.frontmatter.path}/`}>
                  {node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>
      <div className='flex flex-wrap justify-center items-stretch -mx-2'>
        {services.map(({ node }) => (
          <Service
            title={node.frontmatter.title}
            slug={node.frontmatter.slug}
            url={node.frontmatter.path}
            key={node.frontmatter.path}
            icon={node.frontmatter.icon}
            price={node.frontmatter.price}
            blurb={node.frontmatter.blurb}
          ></Service>
        ))}
      </div>
      <div className='flex flex-col'>
        <div className='self-center my-3'>
          <Link to='/services/'>
            <StyledButton>View all services</StyledButton>
          </Link>
        </div>

        <div className='self-center my-3'>
          <Link to='/contact/'>
            <StyledButton>Find out more</StyledButton>
          </Link>
        </div>
      </div>
      <Guarantee />
    </Layout>
  );
};

export const query = graphql`
  query {
    cities: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/cities/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
          }
        }
      }
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;

export default Cities;
